import React, { useContext, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Station from "../station";
import styles from "./scss/styles.module.scss";
import { MediaInfoContext } from '../../../App';

const StationsList = () => {

  const location = useLocation();
  const playerInfo=useContext(MediaInfoContext);

  const fetchedRL = playerInfo.data;

  useEffect(() => {
    playerInfo.fetchDB(location.pathname);
    }, []);

  const { mediaTileContainer, ldsHourglass, loading } = styles;

  return (
    <>
      {fetchedRL.length === 0 && (
        <div className={loading}>
          <div className={ldsHourglass}></div>
          <div>World Band Radio Scanning...</div>
        </div>
      )}
      <div className={mediaTileContainer}>
        {fetchedRL.length > 0 &&
          fetchedRL
            .filter(
              (record) =>
                record.category === playerInfo.selectedCategory.toLowerCase()
            )
            .map((record) => (
              <Station
                key={record.id}
                record={record}
                playerInfo={playerInfo}
              />
            ))}
      </div>
    </>
  );
};

export default StationsList;
