import React, {useState, useEffect, useContext } from 'react';
import styles from './scss/styles.module.scss';
import graphImage from './images/musicgraph.gif'
import { MediaInfoContext } from '../../../App';
import  OnOff  from '../../switch';

let ap, hr=new Date().getHours();
let mn=(new Date().getMinutes())+5;

ap=hr >=12 ? "PM" : "AM";
if (hr>12) hr=hr-12;//12-11
if (hr===0) hr=12;

mn=mn>59?mn-59:mn;

const MediaDisplay=({setPlayerInfo})=>{
const playerInfo=useContext(MediaInfoContext);
let [digitalClock, setDigitalClock]=useState(getDateTime());
let [displayVisible, setDisplayVisible]=useState(true);

   useEffect(() => { 
      setInterval(()=>{
         setDigitalClock(getDateTime())
      },1000);
    }, []);

   const [volumeRange, setVolumeRange]=useState(10);

   const [sleepTimer, setSleepTimer]=useState({
      setHour:hr,
      setMinute:mn,
      setAMPM:ap,
      timerOn:false,
      cursorOff:false,
      intervalID:null
   });


   const setVolume=(e)=>{
    parseInt(e.target.value)===100 && window.navigator.vibrate(500);
    setVolumeRange(e.target.value);
    playerInfo.audioPlayer.volume=volumeRange/100;
}

function getDateTime(){
   let ctr=0;
   const today = new Date();
   const getDay = today.getDay();
   const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
   const monthNames=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
   const weekday = days[getDay];
   const month = today.getMonth();
   const date = today.getDate();
   // const year=today.getFullYear();
   let hour = today.getHours();
   const ampm = hour >=12 ? "PM" : "AM";
   hour = hour > 12 ? hour - 12 : hour;
   hour = hour < 9 ? '0' + hour : hour;
   let minute = today.getMinutes();
   let seconds=today.getSeconds();
   minute = minute < 9 ? '0' + minute : minute;
   // const seconds = today.getSeconds();
   const dateTime = `${monthNames[month]} ${date} ${weekday} ${hour}:${minute} ${ampm}`;
   
   //  if (playerInfo.speak && minute%30===0 && seconds===2){
   //     speechSynthesis.speak(new SpeechSynthesisUtterance(`Time is ${hour}:${minute} ${ampm}`));
   // }
   
return dateTime;
}


   const {
      digitalDisplay, media, dateTime, band, source, mediaType, courtesy, slider, timer, mainButtons,
      radioConsole, ldsHourglass,  musicgraph, tuning, show, hide, sleepBtn, stream, 
      playstatus, sleep, indicator, redBlink, timerDelimit, showIcon, linkColor, minMax, cursorOff, cursorOn,
      mark
   }=styles;

   let {medianame, frequency, location, type}=(playerInfo.nowPlaying);

   medianame=!medianame || medianame.length===0?"Listen To":medianame;
   location=!location || location.length===0?"Your Favorite Radio":location;
   type=!type || type.length===0?"From Around The World":type;
   frequency=!frequency || frequency.length===0?"Streaming Audio":frequency;
   
   const cursorTurnOn=()=>{
      sleepTimer.cursorOff=false;
      setSleepTimer({...sleepTimer});
      
   }
   
   const HourTyped=(e)=>{
      let hourTyped=e.target.value;
      setSleepTimer({...sleepTimer, setHour:hourTyped});
   }
   
   const MinuteTyped=(e)=>{
      let minuteTyped=e.target.value;
      setSleepTimer({...sleepTimer, setMinute:minuteTyped});
   }
   
   const hourUp=()=>{
      if (sleepTimer.timerOn) return;
      sleepTimer.cursorOff=true;
      let {setHour}=sleepTimer;
      ++setHour;
      setHour= setHour > 12 ? setHour - 12 : setHour;
      setSleepTimer({...sleepTimer, setHour:setHour});
   }

   const minuteUp=()=>{
      if (sleepTimer.timerOn) return;
      sleepTimer.cursorOff=true;
      let {setMinute}=sleepTimer;
      setMinute= ++setMinute > 59 ? 0 : setMinute;
      setSleepTimer({...sleepTimer, setMinute:setMinute});
   }

   const ampmToggle=()=>{
      if (sleepTimer.timerOn) return;
      let {setAMPM}=sleepTimer;
      setAMPM=(setAMPM==='AM') ? 'PM' : 'AM';
      setSleepTimer({...sleepTimer, setAMPM:setAMPM});
   }


   const matchTime=()=>{
/*
ap=hr >=12 ? "PM" : "AM";
if (hr>12) hr=hr-12;//12-11
if (hr===0) hr=12;

*/

  
      if (!sleepTimer.timerOn) return;
      
      let date=new Date();
      let currentHour=date.getHours();
      let currentAMPM=(currentHour>=12) ? "PM":"AM";
      if (currentHour>12) currentHour=currentHour - 12;
      if (currentHour===0) currentHour=12;
      let currentMinute=date.getMinutes();

      const {setHour, setMinute, setAMPM }  = sleepTimer;
      if  (currentHour===parseInt(setHour) && currentMinute===parseInt(setMinute) && currentAMPM===setAMPM){
               playerInfo.playerOff(playerInfo.nowPlaying);
               playerInfo.timerOn=false;
               sleepTimer.timerOn=false;
               setSleepTimer({...sleepTimer});
               clearInterval(sleepTimer.intervalID);            
            }

   }

   const sleepTimerOn=()=>{
      if (sleepTimer.timerOn){
         clearInterval(sleepTimer.intervalID);
         sleepTimer.timerOn=false;
         setSleepTimer({...sleepTimer});
      }
      else {
         const {setHour, setMinute, setAMPM }  = sleepTimer;
         if (setHour<=0 || setHour>12 || isNaN(setHour)) sleepTimer.setHour=12;
         if (setMinute<0 || setMinute>59 || isNaN(setMinute)) sleepTimer.setMinute=0;
        // window.navigator.vibrate(200);
         sleepTimer.timerOn=true;
         let meridian=sleepTimer.setAMPM;
         meridian=`${meridian.substr(0,1)} ${meridian.substr(1,1)}`;
         let speakTxt=new SpeechSynthesisUtterance(`Sleep timer is set at ${sleepTimer.setHour} ${sleepTimer.setMinute} ${meridian}`);
         speakTxt.volume=1;
         playerInfo.speak && speechSynthesis.speak(speakTxt);
        sleepTimer.intervalID=setInterval(()=>{
            sleepTimer.timerOn && matchTime();
            },1000*2);
      }
      setSleepTimer({...sleepTimer});
   }


return <>
<div className={radioConsole}>
   {
     playerInfo.displayVisible &&  <section onClick={()=>{playerInfo.playerToggle(playerInfo.nowPlaying)}} className={`${digitalDisplay}`}>
   <div className={dateTime}>{digitalClock}<div className={`${sleepTimer.timerOn?showIcon:hide}`}>
      <span role="img" aria-label="Sleep Indicator">🛌</span></div>  </div>
   <div className={media}>{medianame}</div>

   <div className={`${frequency.length===15?stream:band}`}>{frequency}</div>
   
   <div className={source}>{location}</div>
   <div className={mediaType}>{type}</div>
   </section>
}
   
   <div className={courtesy}>
     {
        playerInfo.nowPlaying.website ? 
     <div>
         Courtesy:&nbsp;
         <a className={linkColor} rel="noopener noreferrer" target="_blank" href={playerInfo.nowPlaying.website}>{playerInfo.nowPlaying.medianame}</a>
         </div>
         :
         <div>&nbsp;</div>
}
      <div title="On/Off Display" onClick={playerInfo.toggleDisplay} className={minMax}>&#8645;</div>
      </div>
   <div id="volumePanel">
    <input onChange={(e)=>{setVolume(e)}} type="range" min="1" max="100" step="1" value={volumeRange} 
    className={slider}/>
   </div>
   <div className={mainButtons}>
                  <div className={playstatus}>
                     <span className={`${playerInfo.showMusicGraph?show:hide}`}>
                     <img alt="Music Graph" className={musicgraph} src={graphImage}/>
                     </span>
                     <span id="hourglass" className={`${playerInfo.showTuning?show:hide}`}><span className={ldsHourglass}></span>
                     <span className={tuning}>Tuning...</span>
                     </span>
                  </div>
                  <div className="timer-input">
                  <div>
                        <span className={sleep} id="sleepAlarmToggle">Sleep</span>
                     </div>
                      <div id="sleepContainer">
                        <div   
                       id="sethour">
                           <input className={`${timer} ${sleepTimer.timerOn?indicator:''}
                            ${sleepTimer.cursorOff?cursorOff:cursorOn}`}  maxLength="2" min="1" max="12"
                           onChange={(e)=>HourTyped(e)}
                           onClick={(e)=>cursorTurnOn(e)}
                           onDoubleClick={()=>hourUp()}
                           readOnly={sleepTimer.timerOn}
                           type="text" value={sleepTimer.setHour}/> 
                           </div>
                        <div className={`${timerDelimit} ${sleepTimer.timerOn?redBlink:''}`}>:</div>
                        <div id="setminute">
                        <input className={`${timer} ${sleepTimer.timerOn?indicator:''}
                         ${sleepTimer.cursorOff?cursorOff:cursorOn}`} 
                         maxLength="2" min="1" max="12"
                           onChange={(e)=>MinuteTyped(e)}
                           onClick={(e)=>cursorTurnOn(e)}
                           onDoubleClick={()=>minuteUp()}
                           readOnly={sleepTimer.timerOn}
                           type="text" value={sleepTimer.setMinute}/>
                           </div>
                        <div  id="setampm">
                          
                        <input className={`${timer} ${sleepTimer.timerOn?indicator:''}`}  maxLength="2" min="1" max="12"
                           onChange={(e)=>MinuteTyped(e)}
                           onClick={()=>{ampmToggle()}}
                           readOnly
                           type="text" value={sleepTimer.setAMPM}/>
                           </div>
                        <div>
                           <span><OnOff ev={sleepTimerOn} sleep={sleepTimer}/></span>
                        </div>
                     </div>
                    <div>&nbsp;</div>
                  </div>
               </div>

               </div>
</>


}

export default  MediaDisplay;