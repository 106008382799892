import React from 'react';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';



  
const OnOff=({ev, sleep})=> {

    const useStyles = makeStyles({
        trackColor: {
          backgroundColor: '#fff'
        },
      });
      const classes = useStyles();

//   const [state, setState] = React.useState({
//     checkedA: false,
//     checkedB: true,
//   });

  const handleChange = (event) => {
   // setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div>
      <Switch
        checked={sleep.timerOn}
        onChange={ev}
        name="checkedA"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
        classes={{
      
            track: classes.trackColor
    
          }}
      />
  
    </div>
  );
}

export default OnOff;