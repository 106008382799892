import React from 'react';
import styles from '../scss/styles.module.scss'

const About=()=>{

   const {
      page, para, paraHdr
   }=styles;

return <>
   <section className={page}>
<div className={paraHdr}>About</div>
<p className={para}>
   This website is developed purely based on passion and enthusiasm 
   for media and web technology. This website do not endorse any third party companies, 
   products or services. 
</p>

<p className={para}>This website should be used only for personal entertainment
purposes.</p>


<p className={para}>Email:noothankrishnan@relaxinthelounge.com
</p>
<br/>

   </section>
</>

}

export default  About;