import React, { useContext } from 'react';
import OnOff from '../switch';
import MediaDisplay from './mediadisplay';
import RadioMenu from './radiomenu'
import StationsList from './stationslist';
import { MediaInfoContext } from '../../App';
import SpeakSwitch from './speakswitch';
import styles from './styles.module.scss';

function Radio() {
  const playerInfo=useContext(MediaInfoContext);
  const {voicePanel, voiceLabel}=styles;
  return (
    <div>
     <MediaDisplay/>
     <RadioMenu/>
     <StationsList/>
     {/* <div className={voicePanel}>
     <div className={voiceLabel}>Voice:</div><SpeakSwitch ev={playerInfo.speakToggle} speak={playerInfo.speak}/>
     </div> */}
    </div>
  );
}

export default Radio;
