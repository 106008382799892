import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './scss/styles.module.scss';
import AppMenu from '../appmenu';

const Footer=({activePlayerURL})=>{

    const goHome=()=>{
        history.push('/');
      }

const history=useHistory();
let [showHideTV, setShowHideTV]=useState(true);
const showHideTVMenu=()=>{
setShowHideTV(!showHideTV);
}

const {flexLcr, footerContainer, title, subTitle}=styles;

return <>
<div className={footerContainer}>
{/* { 
showHideTV && <div> 
<AppMenu/>
</div>} */}
<div onClick={goHome} className={flexLcr}>
        <div className="left">
        </div>
        <div className="center">
               <div>
                       <div className={title}>Relax in the Lounge</div>
                       <div className={subTitle}>Just sit back and enjoy!</div>
               </div>

        </div>

       <div className="hamburger">
    
       </div>

       </div>
   
       </div>
    </>

}

export default Footer
