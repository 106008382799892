import React, { Fragment, useContext } from 'react';
import styles from './scss/styles.module.scss';
import { MediaInfoContext } from '../../../App';


const RadioMenu=()=>{
    const playerInfo=useContext(MediaInfoContext);

    const {dispOnContainer, dispOffContainer, radioMenu, hide, show, triangle}=styles;

    const radioMenuList=[
        {'id':1,'lang':'Music'},
        {'id':2,'lang':'International'},
        {'id':3,'lang':'Malayalam'},
        {'id':4,'lang':'Hindi'},
        {'id':5,'lang':'Tamil'},
        {'id':6,'lang':'Devotional'}
    ]

    return <>
    <div onClick={()=>playerInfo.toggleCategoryMenu()} 
    className={`${playerInfo.displayVisible?dispOnContainer:dispOffContainer} ${radioMenu}`} >{playerInfo.selectedCategory}
    {playerInfo.categoryMenuVisible? <span className={triangle}>▲</span>:<span className={triangle}>▼</span>}
    </div>
    <div className={playerInfo.categoryMenuVisible?show:hide}>
    {
        radioMenuList.map((rec)=>{
            return <Fragment key={rec.id}>
            {
           rec.lang!==playerInfo.selectedCategory &&  
           <div className={radioMenu} 
           onClick={()=>{playerInfo.setCategory(rec.lang)}}>{rec.lang}
           </div>
            }
            </Fragment>

        })
    }
</div>
    </>

}

export default RadioMenu;