import React, { useState, useEffect } from 'react';
import {Switch, Route, Link, useHistory, useLocation} from 'react-router-dom';
import Radio from './components/radio/';
import Television from './components/tv';
// import News from './components/news';
import Footer from './components/footer';
import styles from './assets/scss/app.module.scss';
import apiFetch from './assets/helper/apifetch';
import reactLogo from '../src/assets/images/reactlogo.webp';
import sassLogo from '../src/assets/images/sasslogo.webp';
import linuxLogo from '../src/assets/images/linuxlogo.webp';
import apacheLogo from '../src/assets/images/apachelogo.webp';
import mysqlLogo from '../src/assets/images/mysqllogo.webp';
import phpLogo from '../src/assets/images/phplogo.webp';
import About from './components/disclosure/about';
import QSG from './components/QSG';
// import Album from './components/album';

export const MediaInfoContext=React.createContext();

function App() {
  // var {registerObserver} = require('react-perf-devtool');
  // registerObserver();
const history=useHistory();
const location=useLocation();

const {logoContainer, logo, powered, flexColumn, footerLinks, 
  pipe, center }=styles;


const playerOn=(rec)=>{
  playerInfo.nowPlaying=rec;
  playerInfo.audioPlayer.src=rec.stream;
  playerInfo.activeStationId=rec.id;
  playerInfo.audioPlayer.play();
  playerInfo.showMusicGraph=false;
  playerInfo.showTuning=true;
  setPlayerInfo({...playerInfo})
 }

 const playerOff=(rec)=>{
  playerInfo.audioPlayer.pause();
  playerInfo.activeStationId=rec.id;
  playerInfo.showMusicGraph=false;
  playerInfo.showTuning=false;
  setPlayerInfo({...playerInfo})
 }


const playerToggle=(rec)=>{
  playerInfo.categoryMenuVisible=false;
  if (playerInfo.audioPlayer.paused){
      playerOn(rec);
   }
   else {
    if (playerInfo.activeStationId===rec.id){
    playerOff(rec);
    }
    else {
      playerOn(rec);
    }
   }
   
}


 
const setCategory=(category)=>{

  playerInfo.selectedCategory=category;
  playerInfo.categoryMenuVisible=false;
  setPlayerInfo({...playerInfo})
 }

 const toggleCategoryMenu=()=>{
  playerInfo.categoryMenuVisible=!playerInfo.categoryMenuVisible
  setPlayerInfo({...playerInfo})
}


const toggleDisplay=(e)=>{
  e.preventDefault()
  playerInfo.displayVisible=! playerInfo.displayVisible
  setPlayerInfo({...playerInfo})
}

const speakToggle=(e)=>{
  playerInfo.speak=e.target.checked?true:false;
  setPlayerInfo({...playerInfo});
}

const fetchDB=(path)=>{
  
  let category=null;

  switch (path)   {
    case '/':
      category='radio';
      break;
    case '/radio':
      category='radio';
      break;
    case '/news':
      category='news';
      break;
  }
  
  if (category){
    apiFetch(`/fetchdata.php?mediatype=${category}`, 'GET').then(data=>{
      playerInfo.data=data;
     setPlayerInfo({...playerInfo})
    });
  }
     

}

const [playerInfo, setPlayerInfo]=useState({
  data:[],
  audioPlayer:new Audio(),
  activePlayerURL:'',
  showMusicGraph:false,
  showTuning:false,
  categoryMenuVisible:false,
  toggleCategoryMenu:toggleCategoryMenu,
  activeStationId:'',
  selectedCategory:'Music',
  playerToggle:playerToggle,
  setCategory:setCategory,
  playerOff:playerOff,
  playerOn:playerOn,
  nowPlaying:{},
  timerOn:false,
  showQSG:false,
  displayVisible:true,
  toggleDisplay:toggleDisplay,
  speakToggle:speakToggle,
  fetchDB:fetchDB,
  speak:true
});

const dataLoaded=()=>{
  playerInfo.showMusicGraph=true;
  playerInfo.showTuning=false;
  setPlayerInfo({...playerInfo})
}

const showQSG=()=>{
  playerInfo.showQSG=!playerInfo.showQSG;
  setPlayerInfo({...playerInfo})
  history.push('/');
}

const hideQSG=(target)=>{
  playerInfo.showQSG=false;
  setPlayerInfo({...playerInfo});
  history.push(target);
}

const doNavigate=(target)=>{
  hideQSG();
  history.push(target);
}



useEffect(() => {

fetchDB(location.pathname);

  playerInfo.audioPlayer.onloadeddata=()=>{
    dataLoaded();
  }
  playerInfo.audioPlayer.volume=0.1;
  playerInfo.audioPlayer.preload='none';

}, []);

  return (
    <div>
      <MediaInfoContext.Provider value={playerInfo}>
    <Switch>
          {/* <Route path={["/","/radio"]} exact render={()=><Radio/>}/> */}
          <Route  path={["/","/radio"]}  exact component={Radio} />
          {/* <Route path="/news"  component={News} exact/> */}
          {/* <Route path="/album"  component={Album} exact/> */}
          {/* <Route path="/revamp" exact render={(props)=><Radio {...props}/>}/>*/}
          <Route path="/tv" component={Television} exact/>
          {/* <Route path="/news" component={News}/> */}
          <Route path="/about" component={About}/>
          {/* <Route path="/magazines" component={Magazines} exact/>
          <Route path="/videos" component={Videos} exact/>  */}
    </Switch> 
{
playerInfo.showQSG && <div className={center}>
<QSG hideQSG={hideQSG} />
</div>
}

<div className={flexColumn}>

<div className={footerLinks}>
  <Link onClick={()=>hideQSG('/')} to="/"  title="Home">Home</Link>
  <div className={pipe}>|</div>
  <Link to="/about" onClick={()=>doNavigate('/about')}  title="About">About</Link>
  <div className={pipe}>|</div> 
  <Link to="/" onClick={()=>showQSG('/')} title="User Manual">Quick Start Guide</Link>
</div>

<div className={powered}>Powered By:</div>
<div className={logoContainer}>

  <a target="_blank" rel="noopener noreferrer" title="Visit: reactjs.org" href="https://reactjs.org/">
    <img alt={'React Logo'} className={logo} src={reactLogo} />
  </a>

  <a target="_blank" rel="noopener noreferrer" title="Visit: sass-lang.com" href="https://sass-lang.com/">
  <img alt={'SASS Logo'} className={logo} src={sassLogo} />
  </a>

  <a target="_blank" rel="noopener noreferrer" title="Visit: linux.org" href="https://www.linux.org/">
  <img alt={'Linux Logo'} className={logo} src={linuxLogo} />
  </a>

  <a target="_blank" rel="noopener noreferrer" title="Visit: httpd.apache.org" href="https://httpd.apache.org/">
  <img alt={'Apache Logo'} className={logo} src={apacheLogo} />
  </a>

  <a target="_blank" rel="noopener noreferrer" title="Visit: mysql.com" href="https://www.mysql.com/">
  <img alt={'MySQL Logo'} className={logo} src={mysqlLogo} />
  </a>

  <a target="_blank" rel="noopener noreferrer" title="Visit: php.net" href="https://www.php.net/">
  <img alt={'PHP Logo'} className={logo} src={phpLogo} />
  </a>

</div>
</div>
    <Footer/>

    </MediaInfoContext.Provider>

    </div>
  );
}

export default App;
