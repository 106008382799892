const apiFetch=(url, method, data=null)=>{
    const init={
        method:method,
        body:data?JSON.stringify(data):data,
        headers: { 
          "Content-Type": "application/json",
        }
    }

    return fetch(url, init).then(response=>response.json());

    }
    
    export default apiFetch;