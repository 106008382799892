import React, { useState, useEffect } from 'react';
import MediaDisplay from './mediadisplay';
import NewsWireList from './newswirelist';
import NewsMenu from './newsmenu';


const TV=()=>{
  const toggleCategoryMenu=()=>{
    newsInfo.categoryMenuVisible=!newsInfo.categoryMenuVisible
    setNewsInfo({...newsInfo})
  }

  const setCategory=(lang)=>{
    newsInfo.selectedCategory=lang;
    newsInfo.categoryMenuVisible=false;
    setNewsInfo({...newsInfo})
  }

  const [newsInfo, setNewsInfo]=useState({
    selectedCategory:'International',
    toggleCategoryMenu:toggleCategoryMenu,
    setCategory:setCategory,
    categoryMenuVisible:false,
  })



  return (
    <div>
HERE
      {/* <MediaDisplay/> */}
      {/* <NewsMenu newsInfo={newsInfo}/>
     <NewsWireList newsInfo={newsInfo}/> */}
    </div>
  );
}

export default TV;
