import React, { Fragment } from 'react';
import styles from './scss/styles.module.scss'

const Station=({record, playerInfo})=>{
    
const {radioTile, selectedStation, colorMix}=styles;

return <Fragment key={record.id}>
<div className={`${radioTile} ${playerInfo.activeStationId===record.id && playerInfo.showMusicGraph?colorMix:''} ${playerInfo.activeStationId===record.id ? selectedStation : ''} `}  
onClick={()=>{playerInfo.playerToggle(record)}}>
{record.medianame}
</div>
</Fragment>
}

export default Station;