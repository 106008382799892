import React from 'react';
import styles from './scss/styles.module.scss';

const QSG=({hideQSG})=>{
    const { box, boxHdr, boxContent, boxFtr, line }=styles;
  
    return <>

     <div className={box}>
      <div className={boxHdr}>Quick Start Guide</div>
      <ul className={boxContent}>
          <li className={line}><b>Play: </b>Tap on the desired radio station tile</li>
          <li className={line}><b>Mute: </b>Tap on the digital display at the top of the screen or re-tap on the tile
          </li>
          <li className={line}><b>Volume: </b>Hold and move the slider button towards right or left</li>
          <li className={line}><b>Play in Background: </b> While the radio is playing, tap on the Home button
         at the bottom of your mobile phone. The radio will continue to play while you can use other applications.
         To return to the playing radio, tap on the recent/overview button (vertical three lines), select the browser 
         which is playing the radio.
         </li>
          <li className={line}><b>Audio Quality: </b><u>For HD quality sound reproduction, connect your device
         to an external speaker</u></li>
          <li className={line}><b>Sleep Timer Set: </b>
         Tap on the Hour/Minute/AM-PM display. 
         <br/>Use backspace to remove the existing Hour/Minute, then type the desired Hour/Minute. 
         AM-PM toggles between AM and PM. Radio will be automatically turned off at the set time.
         Alternately to increase the Hour/Minute up by 1, just double-click on Hour/Minute.
         <br/>Note: <u>This will work only when the timer is in the off state.</u></li>
          <li className={line}><b>Mobile Shortcut: </b>To create an App Icon on your mobile screen, 
          click on the vertical ellipsis(⋮) on the top right corner of your Chrome browser, 
          Select "Add to Home Screen", then select "Add".
          </li>
          <li className={line}><b>Desktop Shortcut: </b>To create an Icon on your desktop, 
            click on the icon on the address bar (extreme left), then drag and drop
            into the desktop
         </li>
      </ul>
      <div onClick={hideQSG} className={boxFtr}>Close</div>
     </div>

    </>

}

export default QSG;